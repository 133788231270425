<template>
  <form-builder
    ref="formBuilder"
    v-model="valueModel"
    enable-cache
    remove-label-suffix
    class="tasks-filters"
    :schema="schema"
    :initial-data="value"
  >
  </form-builder>
</template>

<script>
import FormBuilder from '@/components/schema/FormBuilder.vue';

import { schema, schemaRules, schemaForDesktop, schemaForDesktopRules } from '@/schemas/tasksFilterList.schema';
import { RECURRENCE } from '@/constants/entityStatuses';
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'TasksFilterList',
  components: { FormBuilder },
  inject: ['media'],
  props: {
    value: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    schema() {
      if (this.media.isMobile) {
        return this.status === RECURRENCE ? schemaRules : schema;
      }

      return this.status === RECURRENCE ? schemaForDesktopRules : schemaForDesktop;
    },
    valueModel: createModelWrapper('value', 'input'),
  },
};
</script>

<style lang="scss">
.tasks-filters {
  margin-bottom: 32px;
  .form-flex-row {
    &__item-searchBar {
      flex-grow: 4;
      margin-right: 80px;
    }
    &__item-lookTasks {
      flex-grow: 0;
    }
  }
}
</style>
