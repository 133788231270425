<template>
  <div class="tasks">
    <task-modal-change-status
      v-model="dataChangeStatus.isShowChangeStatus"
      :new-status="dataChangeStatus.newStatus"
      :task-id="dataChangeStatus.id"
      @success-change="showStatusChangeSuccessNotice"
      @fail-change="showStatusChangeFailNotice"
    />
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('tasks.back_to_tasks') }}</v-btn>
    </info-modal>
    <base-dialog ref="deleteDialog" :title="$t('task.delete_message')" :text="$t('task.delete_text')" />

    <entity-status-list :statuses="entityTasksStatuses">
      <div v-if="!media.isMobile" class="d-flex">
        <template v-if="!media.isMobile && canUpdate">
          <v-menu offset-y left>
            <template #activator="{ attrs, on }">
              <v-btn large class="mr-3 elevation-0" color="primary" v-bind="attrs" v-on="on">
                {{ $t('button.add') }}
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="issueTask">
                {{ $t('button.add_task') }}
              </v-list-item>

              <v-list-item @click="issueRecurrenceTask">
                {{ $t('button.add_reccurence_task') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-if="status !== RECURRENCE"
          color="secondary"
          class="primary--text mr-3"
          large
          elevation="0"
          @click="exportTasks"
        >
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
      </div>
      <tasks-mobile-menu
        v-else-if="isDataMissing"
        :page="page"
        :status="status"
        :can-update="canUpdate"
        @export-tasks="exportTasks"
      />
    </entity-status-list>

    <tasks-missing-data v-if="isDataMissing" class="tasks__missing-data" :status="status" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <mobile-sort class="mt-2" reverse :sort-list="tasksHeaders" :sort-by="activeHeader" :options.sync="options">
          <tasks-mobile-menu :page="page" :status="status" :can-update="canUpdate" @export-tasks="exportTasks" />
        </mobile-sort>

        <div class="d-flex align-center mb-5">
          <mobile-search-menu
            :key="$route.name"
            v-model="queryModel"
            :placeholder="$t('tasks.search')"
            single-line
            full-width
            hide-details
          />

          <icon-button @click="showModalFilter">
            <v-icon>
              mdi-filter
            </v-icon>
          </icon-button>
          <tasks-modal-filters v-model="isShowModalFilter" :filters.sync="filterList" :status="status" />
        </div>
        <tasks-mobile-list
          :tasks="tasks"
          :can-update="canUpdate"
          @change-status="changeStatus"
          @delete="deleteTask"
          @edit="editTask"
          @cancel-task="cancelTask"
          @open-detailed="openTask"
        />
      </div>

      <tasks-filter-list v-if="!media.isMobile" v-model="filterList" :status="status" />
      <base-table
        v-if="!media.isMobile"
        hide-default-footer
        :items="tasks"
        :item-class="rowClass"
        :headers="tasksHeaders"
        :loading="tableLoading"
        :options.sync="options"
        :server-items-length="tasksLimit"
        @click:row="openTask"
      >
        <template v-slot:item.status="{ item }">
          <span :class="getClassStatus(item.status)">{{ item.status && $t(`tasks.${item.status}`) }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:item.performers="{ item }">
          {{ joinWithLimit(item.performers, 2) }}
        </template>
        <template v-slot:item.menu="{ item }">
          <task-menu
            v-if="showTaskMenu(item)"
            color="blue50"
            :status="item.status"
            :can-update="canUpdate"
            @change-status="changeStatus({ newStatus: $event, id: item.id })"
            @delete="deleteTask(item.id)"
            @edit="editTask(item)"
          />
        </template>
      </base-table>
      <base-pagination v-if="pageCount" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
import EntityStatusList from '@/components/EntityStatusList.vue';
import TasksMissingData from '@/components/Tasks/MissingData.vue';
import TasksFilterList from '@/components/Tasks/FilterList.vue';
import BaseTable from '@/components/BaseTable.vue';
import TaskMenu from '@/components/Tasks/TaskMenu.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import TasksMobileMenu from '@/components/Tasks/Menu.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import IconButton from '@/components/IconButton.vue';
import TasksModalFilters from '@/components/Tasks/ModalFilterList.vue';
import TasksMobileList from '@/components/Tasks/MobileList.vue';
import BasePagination from '@/components/BasePagination.vue';
import TaskModalChangeStatus from '@/components/Tasks/ModalChangeStatus.vue';
import InfoModal from '@/components/InfoModal.vue';
import BaseDialog from '@/components/BaseDialog.vue';

import { createModelData } from '@/schemas/createModelData';
import { schemaForDesktop } from '@/schemas/tasksFilterList.schema';

import {
  TASKS,
  TASKS_ALL,
  TASKS_IN_PROGRESS,
  TASKS_CANCELED_AND_COMPLETED,
  TASKS_RECURRENCE,
  TASKS_CREATE,
  TASKS_RECURRENCE_CREATE,
  TASKS_DETAILED,
  TASKS_RECURRENCE_DETAILED,
  TASKS_EDIT,
  TASKS_RECURRENCE_EDIT,
} from '@/constants/routes';
import { RECURRENCE } from '@/constants/entityStatuses';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import * as taskStatuses from '@/constants/taskStatuses';
import { TASKS_LIST } from '@/constants/analyticsActions';

import { flushPromises } from '@/utils/scheduler';
import { translateDate } from '@/utils/dateFormatting';
import format from 'date-fns/format';
import { throttle } from '@/utils/delay';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { camelToSnake } from '@/utils/formatters';
import { isEqual } from '@/utils/common';
import joinWithLimit from '@/utils/joinWithLimit';

import client from '@/http/client';
import issuesService from '@/services/issues';
import analyticsService from '@/services/analytics';

export default {
  name: 'Tasks',
  components: {
    EntityStatusList,
    TasksMissingData,
    TasksFilterList,
    BaseTable,
    TaskMenu,
    MobileSort,
    TasksMobileMenu,
    MobileSearchMenu,
    IconButton,
    TasksModalFilters,
    TasksMobileList,
    BasePagination,
    TaskModalChangeStatus,
    InfoModal,
    BaseDialog,
  },
  inject: ['media'],
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      isDataMissing: false,
      filterList: createModelData(schemaForDesktop),
      tasks: [],
      options: { sortBy: '', sortDesc: null },
      tasksLimit: 10,
      tableLoading: false,
      isShowModalFilter: false,
      dataChangeStatus: {
        isShowChangeStatus: false,
        newStatus: '',
        id: 0,
      },
      dataDeleteTask: {
        isShowModalDelete: false,
        taskId: 0,
      },
      modal: {
        show: false,
        message: '',
        info: true,
      },
      RECURRENCE,
    };
  },
  computed: {
    entityTasksStatuses() {
      return [
        { id: 0, text: this.$t('tasks.pending'), name: TASKS },
        { id: 1, text: this.$t('tasks.in_progress'), name: TASKS_IN_PROGRESS },
        { id: 2, text: this.$t('tasks.canceled_and_completed'), name: TASKS_CANCELED_AND_COMPLETED },
        { id: 3, text: this.$t('tasks.all'), name: TASKS_ALL },
        {
          id: 4,
          text: this.$t('tasks.recurrence'),
          name: TASKS_RECURRENCE,
          tooltip: this.$t('task.recurrence_tab_tooltip'),
        },
      ];
    },
    isRecurrence() {
      return this.status === RECURRENCE;
    },
    taskRoute() {
      return { name: TASKS_CREATE };
    },
    tasksHeaders() {
      if (this.isRecurrence) {
        return [
          { text: this.$t('tasks.title'), value: 'name', sortable: true, width: '12%' },
          { text: this.$t('tasks.project'), value: 'project', sortable: true, width: '12%' },
          { text: this.$t('tasks.building'), value: 'building', sortable: true, width: '12%' },
          { text: this.$t('tasks.unit'), value: 'unit', sortable: true, width: '12%' },
          { text: this.$t('tasks.executor'), value: 'performer', sortable: true, width: '14%' },
          {
            text: this.$t('task.first_task'),
            value: 'issueDateFirst',
            width: '14%',
          },
          {
            text: this.$t('task.last_task'),
            value: 'issueDateLast',
            width: '14%',
          },
          {
            text: this.$t('task.reccurence_period'),
            value: 'recurrencePeriod',
            width: '15%',
          },
          { text: '', value: 'menu', sortable: false, width: '4%' },
        ];
      }
      return [
        {
          text: '№',
          value: 'id',
          sortable: true,
          width: '5%',
        },
        {
          text: this.$t('tasks.issue_date'),
          sortable: true,
          value: 'createdAt',
          width: '10%',
        },
        { text: this.$t('tasks.title'), value: 'name', sortable: true, width: '12%' },
        { text: this.$t('tasks.project'), value: 'project', sortable: true, width: '14%' },
        { text: this.$t('tasks.building'), value: 'building', sortable: true, width: '14%' },
        { text: this.$t('tasks.unit'), value: 'unit', sortable: true, width: '11%' },
        { text: this.$t('label.performers'), value: 'performers', sortable: true, width: '20%' },
        { text: this.$t('tasks.status'), value: 'status', sortable: true, width: '10%' },
        { text: '', value: 'menu', sortable: false, width: '4%' },
      ];
    },
    activeHeader() {
      return this.tasksHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },
    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },
    offset() {
      return (this.page - 1) * this.tasksLimit;
    },
    canUpdate() {
      return this.$can(UPDATE, subjects.ISSUES);
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.tasksHeaders.find(tasksHeaders => tasksHeaders.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
  },
  watch: {
    status(newStatus, oldStatus) {
      this.$nextTick(async () => {
        if (newStatus === RECURRENCE || oldStatus === RECURRENCE) {
          this.options = { sortBy: '', sortDesc: null };
        }
        this.tasks = [];
        await this.getTasks();
      });

      this.isDataMissing = false;
      this.query = '';
    },

    filterList: {
      handler(newValue, oldValue) {
        if (isEqual(newValue, oldValue)) {
          return;
        }
        this.queryModel = newValue.searchBar;
        this.$options.throttledSearch();
      },
      deep: true,
    },

    orderBy() {
      this.getTasks();
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
        this.getTasks();
      }
    },

    // eslint-disable-next-line
    '$route.name': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.isDataMissing) {
        this.isDataMissing = false;
      }
      if (this.pageModel !== 1) {
        this.pageModel = 1;
        return;
      }
      this.getTasks();
    },

    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$options.throttledSearch();
    },
  },
  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getTasks();
    }, 500);
  },
  mounted() {
    analyticsService.track(TASKS_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });

    this.getTasks()
      .then(() => {
        if (this.tasks.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    changeStatus({ newStatus, id }) {
      this.dataChangeStatus.isShowChangeStatus = true;
      this.dataChangeStatus.newStatus = newStatus;
      this.dataChangeStatus.id = id;
    },
    async deleteTask(issueId) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.tableLoading = true;

        const deleteIssue = this.isRecurrence ? issuesService.deleteRecurrenceIssueById : issuesService.deleteIssueById;
        try {
          await deleteIssue(issueId);
          this.showModal(this.$t('tasks.success_delete_task'));
          this.getTasks();
        } catch (e) {
          this.showModal(this.$t('tasks.fail_delete_task'));
        } finally {
          this.tableLoading = false;
        }
      }
    },
    issueTask() {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_CREATE, query: { prevPage: this.page } });
        return;
      }

      this.$router.push({ name: TASKS_CREATE, query: { prevPage: this.page } });
    },
    issueRecurrenceTask() {
      this.$router.push({ name: TASKS_RECURRENCE_CREATE, query: { prevPage: this.page } });
    },
    editTask({ id }) {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_EDIT, params: { id } });
      } else this.$router.push({ name: TASKS_EDIT, params: { id } });
    },
    cancelTask(id) {
      this.changeStatus({ newStatus: taskStatuses.CANCELED, id });
    },
    showStatusChangeSuccessNotice() {
      this.showModal(this.$t('tasks.success_change_status'));
      this.getTasks();
    },
    showStatusChangeFailNotice() {
      this.showModal(this.$t('tasks.fail_change_status'));
    },
    exportTasks() {
      const filter = {
        status: this.status,
        buildings: this.normalizeItemFilter(this.filterList.buildings),
        units: this.normalizeItemFilter(this.filterList.units),
        projects: this.normalizeItemFilter(this.filterList.projects),
        clients: this.normalizeItemFilter(this.filterList.clients),
        forMe: this.filterList.lookTasks === 'onlyMy',
      };
      issuesService.downloadTasks(filter, 'tasks.xlsx');
    },
    openTask({ id }) {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_DETAILED, params: { id } });
      } else this.$router.push({ name: TASKS_DETAILED, params: { id } });
    },
    async getTasks() {
      if (this.$options.cancelRequestTasks) {
        this.$options.cancelRequestTasks();
        await flushPromises();
      }

      this.tableLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestTasks = cancelSource.cancel;
        const getTasks = this.isRecurrence ? issuesService.getRecurrenceIssue : issuesService.getIssue;

        const data = await getTasks(
          {
            limit: this.tasksLimit,
            offset: this.offset,
            search: this.query,
            orderBy: this.orderBy,
            status: this.status,
            buildings: this.normalizeItemFilter(this.filterList.buildings),
            units: this.normalizeItemFilter(this.filterList.units),
            projects: this.normalizeItemFilter(this.filterList.projects),
            clients: this.normalizeItemFilter(this.filterList.clients),
            performers: this.normalizeItemFilter(this.filterList.performers),
            forMe: this.filterList.lookTasks === 'onlyMy',
          },
          {
            cancelToken: cancelSource.token,
          }
        );

        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.tasksLimit);

        this.tasksCount = count;

        this.tasks = this.isRecurrence
          ? results.map(contract => this.normalizeRecurrenceData(contract))
          : results.map(contract => this.normalizeData(contract));
      } finally {
        this.tableLoading = false;
        this.$options.cancelRequestTasks = null;
      }
    },
    normalizeData(task) {
      return {
        id: task.id,
        name: task.name,
        createdAt: task.createdAt,
        project: task.project.name,
        building: task.building.name,
        unit: task.unit.name,
        performers: task.performers.map(performer => {
          return {
            id: performer.id,
            name: `${performer.firstName} ${performer.lastName}`,
          };
        }),
        status: task.status,
        client: task.client ? `${task.client?.firstName} ${task.client?.lastName}` : null,
        forMe: task.forMe,
      };
    },
    normalizeRecurrenceData(task) {
      return {
        id: task.id,
        createdAt: task.createdAt,
        name: task.name,
        project: task.project.name,
        building: task.building.name,
        unit: task.unit.name,
        performer: `${task.performer.firstName} ${task.performer.lastName}`,
        issueDateFirst: this.formatDate(task.issueDateFirst),
        issueDateLast: this.formatDate(task.issueDateLast),
        recurrencePeriod: this.$t(task.recurrencePeriod.name),
        client: task.client ? `${task.client.firstName} ${task.client.lastName}` : null,
        forMe: task.forMe,
      };
    },
    normalizeItemFilter(itemFilter) {
      return checkEmptyParams(itemFilter) ? undefined : extractParamsFromMultiSelectObject(itemFilter);
    },
    formatDate(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L yyyy'));
      }
      return date;
    },
    rowClass(item) {
      return item.status === taskStatuses.CANCELED ? 'tasks__row--canceled' : '';
    },
    getClassStatus(status) {
      if (status === taskStatuses.PENDING) {
        return 'tasks__status--pending';
      }
      if (status === taskStatuses.IN_PROGRESS) {
        return 'tasks__status--in-progress';
      }
      return '';
    },
    showModalFilter() {
      this.isShowModalFilter = true;
    },
    showTaskMenu(item) {
      if (item.status === taskStatuses.COMPLETED) return false;
      if (item.status === taskStatuses.CANCELED) return false;
      if (item.forMe) return true;
      if (!this.canUpdate) return false;
      return true;
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    joinWithLimit,
  },
  // $options
  cancelRequestTasks: null,
};
</script>

<style lang="scss">
.tasks {
  height: 100%;
  &__missing-data {
    height: 100%;
  }
  &__row--canceled {
    color: $--grey-color-0;
  }
  &__status--pending {
    color: $--blue-color-20;
  }
  &__status--in-progress {
    color: $--blue-color-20;
  }
  &__status--canceled {
    color: $--grey-color-0;
  }
}
</style>
