<template>
  <v-menu v-if="canUpdate" offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title @click="toCreateRoute">
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_task') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-title @click="toCreateRoute">
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_reccurence_task') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!isRecurrence" @click="exportTasks">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Constants
import { TASKS_CREATE, TASKS_RECURRENCE_CREATE } from '@/constants/routes';
import { RECURRENCE } from '@/constants/entityStatuses';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'TasksMobileMenu',

  components: { IconButton },

  inject: ['media'],

  props: {
    page: { type: Number, default: 1 },
    canUpdate: { type: Boolean, default: false },
    status: { type: String, required: true },
  },

  computed: {
    isRecurrence() {
      return this.status === RECURRENCE;
    },
  },
  methods: {
    exportTasks() {
      this.$emit('export-tasks');
    },
    toCreateRoute() {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_CREATE });
        return;
      }

      this.$router.push({ name: TASKS_CREATE });
    },
  },
};
</script>
