<template>
  <base-missing-data-page class="tasks-missing-data" :url-img="$options.imageDataMissing">
    <template v-if="isPending" #message>
      {{ $t('tasks.pending_missing_message') }}
    </template>
    <template v-else-if="isInProgress" #message>
      {{ $t('tasks.progress_missing_message') }}
    </template>
    <template v-else-if="isRecurrence" #message>
      {{ $t('tasks.recurring_missing_message') }}
    </template>
    <template v-else #message>
      {{ $t('tasks.canceled_and_completed_missing_message') }}
    </template>
  </base-missing-data-page>
</template>

<script>
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import * as taskStatuses from '@/constants/taskStatuses';
import imageDataMissing from '@/assets/imageMissingData/missing-data-tasks.svg';

export default {
  name: 'TasksMissingData',

  components: { BaseMissingDataPage },

  props: {
    status: { type: String, default: 'pending' },
  },
  computed: {
    isPending() {
      return this.status === taskStatuses.PENDING;
    },
    isInProgress() {
      return this.status === taskStatuses.IN_PROGRESS;
    },
    isRecurrence() {
      return this.status === taskStatuses.RECURRENCE;
    },
  },
  imageDataMissing,
};
</script>
